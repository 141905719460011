<template>
    <q-modal-view
        id="modPix"
        ref="modal"
        title="Pagamento via PIX"
        block-dismiss
        :disable-dismiss="pixGerado"
    >
        <template v-if="loading">
            <q-pix-loading />
        </template>
        <template v-else>
            <div class="row">
                <q-panel titulo="DETALHES">
                    <div class="row q-mt-sm">
                        <div class="col">
                            <span class="text-subtitle2 text-weight-bold text-blue-grey-4">
                                Valor Total
                            </span>
                            <br>
                            <span class="text-subtitle2">
                                R$ {{ valorTotal }}
                            </span>
                            <br>
                        </div>

                        <div class="col">
                            <span class="text-subtitle2 text-weight-bold text-blue-grey-4">
                                Expira em
                            </span>
                            <br>
                            <span class="text-subtitle2">
                                {{ pixExpiracao }}
                            </span>
                        </div>
                    </div>
                </q-panel>
            </div>
            <q-panel
                v-if="!pixGerado"
                titulo="ATENÇÃO"
            >
                <span v-html="mensagemPix" />
            </q-panel>
            <div
                v-if="pixGerado"
                class="row"
            >
                <q-panel titulo="PAGAMENTO">
                    <q-pix :pix="pix" />
                </q-panel>
            </div>
        </template>
        <footer class="fixed-bottom row">
            <div class="col q-mt-md">
                <q-btn
                    v-if="pixGerado"
                    color="green"
                    label="Finalizar"
                    class="full-width"
                    style="height: 50px;"
                    @click="close"
                />
                <q-btn
                    v-if="!pixGerado"
                    color="green"
                    label="Gerar PIX"
                    class="full-width"
                    style="height: 50px;"
                    @click="criarOrdemPagamento"
                />
            </div>
        </footer>
    </q-modal-view>
</template>

<script>

import QModalView from '@/components/shared/QModalView'
import QPanel from '@/components/shared/QPanel'
import QPix from '@/components/shared/QPix'
import QPixLoading from '@/components/shared/QPixLoading'

import MixinMessages from '@/mixins/mixinMessages'

import OrdemPagamentoService from '@/services/ordens_pagamento'
import ParametrosServices from '@/services/parametros'

import { date } from 'quasar'

export default {
    name: 'QModalPix',

    components: {
        QModalView,
        QPanel,
        QPixLoading,
        QPix
    },

    mixins: [
        MixinMessages
    ],

    props: {
        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        parcelas: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            pix: {
                link_emv: null,
                qrcode: {
                    tipo_arquivo: null,
                    imagem_base64: null
                }
            },
            loading: false,
            mensagemPix: null,
            pixGerado: false,
            parcelaItens: [],
            pixExpiracao: date.formatDate(date.addToDate(Date.now(), { minutes: 30 }), 'DD/MM/YYYY HH:mm')
        }
    },

    computed: {
        valorTotal() {
            let valor = 0
            this.parcelas.map(el => (
                valor = valor + el.valor_total
            ))
            return valor.toFixed(2).replace('.', ',')
        }
    },

    created() {
        this.$_buscaMensagemPix()
    },

    methods: {
        open() {
            this.$refs.modal.show()
            this.$_carregaItensParcelas(this.parcelas)
        },

        criarOrdemPagamento() {
            this.showLoading({ message: 'Gerando o PIX, Aguarde!' })
            this.loading = true
            OrdemPagamentoService.post({
                id_cliente: this.idCliente,
                id_dependente: this.idDependente,
                valor_total: parseFloat(this.valorTotal.replace(',', '.')),
                id_loja: process.env.VUE_APP_ID_LOJA_ECOMMERCE,
                itens: [...this.parcelaItens],
                transacoes: [{
                    tipo: 'Pix',
                    gateway: 'Santander',
                    valor: parseFloat(this.valorTotal.replace(',', '.'))
                }]
            })
                .then(({ data }) => {
                    const transacao = data.transacoes[0]
                    this.hideLoading()
                    this.pix = {
                        link_emv: transacao.pix.link_emv,
                        qrcode: {
                            tipo_arquivo: transacao.pix.qrcode.tipo_arquivo,
                            imagem_base64: transacao.pix.qrcode.imagem_base64
                        }
                    }
                    this.pixExpiracao = date.formatDate(date.addToDate(transacao.data_emissao, { minutes: 30 }), 'DD/MM/YYYY HH:mm')
                    this.pixGerado = true
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao gerar o Pix',
                        error: error
                    })
                    this.$refs.modal.hide()
                })
        },

        $_carregaItensParcelas(parcelas) {
            this.parcelaItens = []
            parcelas.map(el => (
                this.parcelaItens.push({
                    id_parcela: el.id,
                    valor: el.valor_parcela,
                    desconto: el.valor_desconto,
                    juro: el.valor_juro
                })
            ))
        },

        $_buscaMensagemPix() {
            ParametrosServices.getParametro('PIX.MENSAGEM_APP')
                .then(({ data }) => {
                    this.mensagemPix = String(data[0].valor)
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, Atenção de errado aconteceu!',
                    message: 'Ocorreu um erro o buscar a mensagem do PIX.',
                    error: e
                })
                )
        },

        close() {
            this.successMessage({
                title: 'Pagamento solicitado!',
                message: 'Você pode consultar o andamento através da aba segunda via.'
            })
            this.$router.push('/home')
        }
    }
}
</script>

<style>
</style>
