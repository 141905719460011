<template>
    <q-item
        clickable
        v-ripple
        :disable="desabilitado"
        @click="click"
    >
        <q-item-section
            top
            avatar
        >
            <q-avatar
                color="primary"
                text-color="white"
                :icon="icone"
            />
        </q-item-section>

        <q-item-section>
            <q-item-label>
                {{ titulo }}
            </q-item-label>
            <q-item-label caption>
                {{ subtitulo }}
            </q-item-label>
        </q-item-section>

        <q-item-section
            side
            top
            v-if="novo"
        >
            <q-badge label="Novo" />
        </q-item-section>
    </q-item>
</template>

<script>
export default {
    name: 'QItemFormaPagamento',

    props: {
        click: {
            type: Function,
            required: true
        },

        icone: {
            type: String,
            required: true
        },

        titulo: {
            type: String,
            required: true
        },

        subtitulo: {
            type: String,
            required: true
        },

        novo: {
            type: Boolean,
            default: false
        },

        desabilitado: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
</style>
