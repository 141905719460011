<template>
  <div class="row justify-center">
    <div style="width: 100%; max-width: 1200px">
      <q-extrato
        class="q-mb-xl"
        ref="selecaoParcelas"
        :nome="idCliente"
        :todos="tipoCliente === '0'"
        :id-cliente="idCliente"
        :id-dependente="idDependente"
        selecao="multiple"
        @itensSelecionados="$_marcaItensSelecionados"
        marcar-linha-ao-clicar
      />
      <footer class="fixed-bottom row">
        <q-btn
          v-if="existeFormaPagamentoAtiva"
          :color="itensSelecionados.length > 0 ? 'green' : 'grey-8'"
          label="Forma de pagamento"
          class="full-width q-mt-md"
          style="height: 50px"
          @click="$_redirecionaNovoPagamento"
        />
        <q-btn
          v-else
          color="grey-8"
          label="Nenhuma forma de pagamento disponivel no momento"
          disable
          class="full-width q-mt-md"
          style="height: 50px"
        />
      </footer>
      <q-modal-forma-pagamento
        ref="modalFormaPagamento"
        :id-cliente="idCliente"
        :id-dependente="idDependente"
        :parcelas="itensSelecionados"
        :formas-pagamento="formasPagamentoAtiva"
      />
    </div>
  </div>
</template>

<script>
import QExtrato from "@/views/Extrato";
import QModalFormaPagamento from "@/views/FormaPagamento";

export default {
  name: "SelecaoParcelas",

  components: {
    QExtrato,
    QModalFormaPagamento,
  },

  data() {
    return {
      idCliente: null,
      idDependente: null,
      tipoCliente: null,
      itensSelecionados: [],
    };
  },

  mounted() {
    this.idCliente = this.$route.query.idCliente
      ? this.$route.query.idCliente
      : null;
    this.idDependente = this.$route.query.idDependente
      ? this.$route.query.idDependente
      : null;
    this.tipoCliente = this.$route.query.tipoCliente
      ? this.$route.query.tipoCliente
      : 0;
    this.$refs.selecaoParcelas.refresh();
  },

  computed: {
    existeFormaPagamentoAtiva() {
      const lista = this.formasPagamento.filter((item) => item.ativo == true);
      return Boolean(lista.length > 0);
    },

    formasPagamento() {
      return [
        {
          id: 1,
          click: () => this.$refs.modalFormaPagamento.$_gerarPix(),
          icone: "qr_code",
          titulo: "PIX (Baixa da parcela imediata)",
          subtitulo: "Pague através do Link ou escaneie o QRCode.",
          desabilitado: false,
          novo: true,
          ativo: true,
        },
        {
          id: 2,
          click: () => this.$refs.modalFormaPagamento.$_gerarBoleto(),
          icone: "line_style",
          titulo: "Boleto (Baixa da parcela em até 3 dias úteis)",
          subtitulo:
            "Pague suas contas no conforto de sua casa pelo seu smartphone.",
          desabilitado: false,
          novo: false,
          ativo: !this.isTotem,
        },
      ];
    },

    formasPagamentoAtiva() {
      return this.formasPagamento.filter((item) => item.ativo == true);
    },

    isTotem() {
      return Number(process.env.VUE_APP_TOTEM);
    },
  },

  methods: {
    $_marcaItensSelecionados(itens) {
      this.itensSelecionados = itens;
    },

    $_redirecionaNovoPagamento() {
      if (this.itensSelecionados.length > 0) {
        this.$refs.modalFormaPagamento.open();
      }
    },
  },
};
</script>

<style></style>
