<template>
    <div class="col">
        <div class="row">
            <q-panel>
                <div class="row q-mt-sm">
                    <div class="col">
                        <q-skeleton
                            animation="blink"
                            width="120px"
                            height="20px"
                        />
                        <br>
                        <q-skeleton
                            animation="blink"
                            width="80px"
                            height="20px"
                        />
                    </div>

                    <div class="col">
                        <q-skeleton
                            animation="blink"
                            width="120px"
                            height="20px"
                        />
                        <br>
                        <q-skeleton
                            animation="blink"
                            width="80px"
                            height="20px"
                        />
                    </div>
                </div>
            </q-panel>
        </div>
        <div class="row">
            <q-panel>
                <div class="row justify-center">
                    <q-skeleton
                        animation="blink"
                        width="200px"
                        height="200px"
                    />
                </div>
                <div class="row q-mt-md full-width justify-center">
                    <q-skeleton
                        animation="blink"
                        width="200px"
                        height="35px"
                    />
                </div>
            </q-panel>
        </div>
    </div>
</template>

<script>
import QPanel from '@/components/shared/QPanel'
export default {
    name: 'QPixLoading',

    components: {
        QPanel
    }
}
</script>

<style>
</style>
