<template>
    <div :class="`bg-${$q.dark.isActive ? 'dark' : 'white'}`">
        <q-modal-view
            id="modFormaPagamento"
            ref="modal"
            title="Forma de pagamento"
            :dark="$q.dark.isActive ? true : false"
            block-dismiss
            disable-dismiss
        >
            <div :class="`justify-center bg-${$q.dark.isActive ? 'dark' : 'white'}`">
                <q-list>
                    <div
                        v-for="forma in listaFormaPagamentos"
                        :key="forma.id"
                    >
                        <q-item-forma-pagamento
                            v-if="forma.ativo"
                            :click="forma.click"
                            :icone="forma.icone"
                            :titulo="forma.titulo"
                            :subtitulo="forma.subtitulo"
                            :novo="forma.novo"
                            :desabilitado="forma.desabilitado"
                        />
                        <q-separator inset="item" />
                    </div>
                </q-list>
            </div>
            <footer class="fixed-bottom row">
                <div class="col q-mt-md">
                    <q-btn
                        color="primary"
                        label="Cancelar"
                        class="full-width"
                        style="height: 50px;"
                        @click="close"
                    />
                </div>
            </footer>
        </q-modal-view>
        <q-modal-pix
            ref="modalPix"
            :id-cliente="idCliente"
            :id-dependente="idDependente"
            :parcelas="parcelas"
        />
        <q-modal-boleto
            ref="modalBoleto"
            :id-cliente="idCliente"
            :id-dependente="idDependente"
            :parcelas="parcelas"
        />
    </div>
</template>
<script>

import QModalView from '@/components/shared/QModalView'

import QModalBoleto from '@/views/Boleto'
import QModalPix from '@/views/Pix'

import QItemFormaPagamento from '@/components/shared/QItemFormaPagamento'
export default {
    name: 'QModalFormaPagamento',

    components: {
        QModalView,
        QModalBoleto,
        QModalPix,
        QItemFormaPagamento
    },

    props: {
        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        parcelas: {
            type: Array,
            default: null
        },
        formasPagamento: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        listaFormaPagamentos(){
            return this.formasPagamento
        }
    },

    methods: {
        open() {
            this.$refs.modal.show()
        },

        close() {
            this.$refs.modal.hide()
        },

        $_gerarPix() {
            this.close()
            this.$refs.modalPix.open()
        },

        $_gerarBoleto() {
            this.close()
            this.$refs.modalBoleto.open()
        }
    }
}
</script>

<style>

</style>
